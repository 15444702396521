
export default {

  validateBanner(url,banner){
    if((!url || !url.length) && (!banner || !banner.length)){
      return false;
    }
    return true;
  },
  validateTitle(title){
    if(!title || title.length<1 || title.length>30){
      return false;
    }
    return true;
  },
  validateButton(button){
    if(!button || button.length<1 || button.length>10){
      return false;
    }
    return true;
  },
  validateLink(link){
    if(!(new RegExp('^(?:(?:https?|http):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]+$','i')).test(link)){
      return false;
    }
    return true;
  },
  validateRegion(items_region){
    if(items_region.length < 1){
      return false;
    }
    return true;
  },
  validateSection(items_section){
    if(items_section && items_section.length < 1){
      return false;
    }
    return true;
  },
  validateDate(start_date,end_date){
    if(!start_date && !end_date){
      return true;
    }
    if(!start_date || !end_date){
      return false;
    }
    if(new Date(start_date) >= new Date(end_date)){
      return false;
    }
    return true;
  }

}